/*-------------------------*/
/*          Timer          */
/*-------------------------*/
const deadline = new Date("Feb 15, 2023 10:00:00").getTime();
const d = document.getElementById("days");
const h = document.getElementById("hours");
const m = document.getElementById("minutes");
const s = document.getElementById("secondes");

const spanDays = document.querySelector('.span-days');
const spanHours = document.querySelector('.span-hours');
const spanMinutes = document.querySelector('.span-minutes');
const spanSecondes = document.querySelector('.span-secondes');

if (d !==null && h !== null && m !== null && s !== null) {

    const x = setInterval(function () {
        const now = new Date().getTime();
        const t = deadline - now;
        const days = Math.floor(t / (1000 * 60 * 60 * 24));
        const hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((t % (1000 * 60)) / 1000);

        d.innerHTML = days.toString();
        h.innerHTML = hours.toString();
        m.innerHTML = minutes.toString();
        s.innerHTML = seconds.toString();


        if (days > 1) {
            spanDays.innerHTML = 'Jours';
        } else {
            spanDays.innerHTML = 'Jour'
        }

        if (hours > 1) {
            spanHours.innerHTML = 'Heures';
        } else {
            spanHours.innerHTML = 'Heure'
        }

        if (minutes > 1) {
            spanMinutes.innerHTML = 'Minutes';
        } else {
            spanMinutes.innerHTML = 'Minute'
        }

        if (seconds > 1) {
            spanSecondes.innerHTML = 'Secondes';
        } else {
            spanSecondes.innerHTML = 'Seconde'
        }




        if (t < 0) {
            clearInterval(x);
        d.innerHTML = "EXPIRED";
        }
    }, 1000);

}

