

/*-------------------------*/
/*    Hover Catégories     */
/*-------------------------*/
const titles = document.querySelectorAll('.block-category');
let width = 0;

// Desktop
titles.forEach(title => {
    title.addEventListener('mouseover', () => {
        width = document.documentElement.clientWidth;

        if (width > 992) {
            const category = title.children[0].dataset.value;
            const span = document.querySelector(`.title-${category}`);
            const image = document.querySelector(`.${category}`);
            const text = document.querySelector(`.${category}-text`);

            image.style.right = 100 + 'px';
            image.style.opacity = '1';
            span.style.marginLeft = '100px';
            span.style.color = '#FF6F67';
            text.style.height = 'auto';
            text.style.padding = '20px 0 60px 100px';
            text.style.opacity = '1';
        }
    })

    title.addEventListener('mouseout', () => {
        const category = title.children[0].dataset.value;
        const span = document.querySelector(`.title-${category}`);
        const image = document.querySelector(`.${category}`);
        const text = document.querySelector(`.${category}-text`);

        image.style.opacity = '0';
        span.style.marginLeft = '0';
        span.style.color = 'white';
        text.style.height = '0';
        text.style.padding = '0'
        text.style.opacity = '0';
    })
})


// Mobile
titles.forEach(title => {

    title.addEventListener('click', () => {
        const category = title.children[0].dataset.value;
        const span = document.querySelector(`.title-${category}`);
        const image = document.querySelector(`.${category}`);
        const text = document.querySelector(`.${category}-text`);

        width = document.documentElement.clientWidth;

        const elementVisible = document.querySelector('.visible')

        if (elementVisible !== null) {
            let cat = elementVisible.dataset.value;
            const text = document.querySelector(`.${cat}-text`);
            text.style.margin = '0 0 0';
            text.style.opacity = '0';

            let categoryNext = parseInt(cat.split('-')[1]) + 1;

            if (categoryNext <= titles.length) {
                let blockParent = document.querySelector(`.category-${categoryNext}-text`).parentNode;
                blockParent.style.marginTop = '0';
            }

            elementVisible.classList.remove('visible')
        }

        if (width <= 992) {
            const category = title.children[0].dataset.value;
            const span = document.querySelector(`.title-${category}`);
            const image = document.querySelector(`.${category}`);
            const text = document.querySelector(`.${category}-text`);

            span.classList.add('visible');
            span.style.color = '#FF6F67';
            text.style.margin = '10px 15px 0';
            text.style.opacity = '1';

            const textNext = parseInt(category.split('-')[1]) + 1;

            if (textNext <= titles.length) {
                const block = document.querySelector(`.title-category-${textNext}`).parentNode;
                block.style.marginTop = '150px';
            }
        }
    })

})

