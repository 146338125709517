const navbar = document.querySelector('.navbar');
const btn = document.querySelector('.navbar-toggler');
const icon = document.querySelector('.nav-btn');
const items = document.querySelectorAll('.navbar-collapse ul li');
const logo = document.querySelector('.navbar-brand img');

btn.addEventListener('click', () => {

    if (btn !== null && btn.getAttribute('aria-expanded') === 'true') {
        navbar.style.background = '#00EAC7';
        logo.src = '/themes/designawards/assets/images/logos/logo-black.png';

        icon.classList.remove('fa-bars');
        icon.classList.remove('text-white');
        icon.classList.add('fa-xmark')

        document.body.style.overflowY = 'hidden';
    } else {
        document.body.style.overflowY = 'visible';
        icon.classList.remove('fa-xmark');
        icon.classList.add('fa-bars')

        if (window.location.pathname === '/rules' || window.location.pathname.split('/')[1] === 'jury'|| window.location.pathname === '/editions' || window.location.pathname === '/about' || window.location.pathname === '/legal-notices' || window.location.pathname === '/privacy-policy') {
            navbar.style.background = '#121212';
            logo.src = '/themes/designawards/assets/images/logos/logo-white.png';
            icon.classList.add('text-white');
        } else {
            navbar.style.background = '#FF6F67';
        }
    }
})


items.forEach(item => {
    item.addEventListener('click', () => {
        document.body.style.overflowY = 'visible';
        document.querySelector('.navbar-collapse').classList.remove('show');
        if (window.location.pathname === '/rules' || window.location.pathname.split('/')[1] === 'jury' || window.location.pathname === '/editions' || window.location.pathname === '/about' || window.location.pathname === '/legal-notices' || window.location.pathname === '/privacy-policy') {
            navbar.style.background = '#121212';
            logo.src = '/themes/designawards/assets/images/logos/logo-white.png';
            icon.classList.remove('fa-xmark');
            icon.classList.add('text-white');
            icon.classList.add('fa-bars')
        } else {
            navbar.style.background = '#FF6F67';
            icon.classList.remove('fa-xmark');
            icon.classList.add('fa-bars')
        }
    })
})

